import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationService } from '../../../app/services/navigation.service';
import { NavItem } from './nav-item';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements AfterViewInit {
  @ViewChild('drawer') drawer!: MatDrawer;
  showFiller = false;
  navItems: NavItem[] = [
    {
      displayName: 'Stopover',
      iconName: '',
      route: 'stopover',
      children: [
        {
          displayName: 'Stopover Price',
          iconName: '',
          route: 'stopover/stopover-price',
        },
        {
          displayName: 'Hotel Price',
          iconName: '',
          route: 'stopover/hotel-price',
        },
        {
          displayName: 'F1 Hotel Price',
          iconName: '',
          route: 'stopover/f1-hotel-price',
        },
        {
          displayName: 'F1 Hotel Packages',
          iconName: '',
          route: 'stopover/f1-hotel-package',
        },
        {
          displayName: 'Purge',
          iconName: '',
          route: 'stopover/purge',
        },
        {
          displayName: 'Eligibility Rules',
          iconName: '',
          route: 'stopover/eligibility-rules',
        },
        {
          displayName: 'Sorting Rules',
          iconName: '',
          route: 'stopover/sorting-rules',
        },
      ],
    },
  ];
  constructor(private navigationService: NavigationService) {}

  ngAfterViewInit(): void {
    this.navigationService.isToggleSideNav$.subscribe(res => {
      if (res) {
        this.drawer.open();
      } else {
        this.drawer.close();
      }
    });
  }
}
